import { mdiFileDocument, mdiAccount } from '@mdi/js'

export default [
  {
    title: 'Proyectos',
    icon: mdiFileDocument,
    to: 'company.projects'
  },
  {
    title: 'Perfil',
    icon: mdiAccount,
    to: 'company.account'
  }
]
