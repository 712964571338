<template>
  <layout-content-main :nav-menu-items="navMenuItems">
    <!-- Default Slot -->
    <slot></slot>

    <welcome-video :is-show-welcome-video="isShowWelcomeVideo" />
  </layout-content-main>
</template>

<script>
import LayoutContentMain from "./LayoutContentMain";
import navMenuItems from "@/navigation/company";
import WelcomeVideo from "@/components/general/WelcomeVideo.vue";
import { ref } from "@vue/composition-api";

import { useConfigApp } from "@/composables/config-app";

export default {
  components: {
    LayoutContentMain,
    WelcomeVideo,
  },
  setup() {
    const { isShowWelcomeVideo } = useConfigApp();

    return {
      navMenuItems,
      isShowWelcomeVideo,
    };
  },
};
</script>

<style></style>
