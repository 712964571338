import api from "@/services";
import { onMounted, ref } from "@vue/composition-api";
import router from "@/router";

export function useConfigApp() {
  const totalReceiveInfoCompany = ref(0);
  const isBannerInfoCompany = ref(false);

  const loadingValue = ref(false);
  const isShowWelcomeVideo = ref(false);

  const getConfigAppValues = async (key) => {
    try {
      loadingValue.value = true;
      const { data: response } = await api.getConfigAppValues({
        params: {
          key,
        },
      });
      const { data } = response;
      value.value = data.value;
    } catch (error) {
    } finally {
      loadingValue.value = false;
    }
  };

  const getConfigAppCreditCostReveiveInfoCompany = async () => {
    const { data: response } = await api.getConfigAppValues({
      params: {
        key: "credit_cost_receive_info_company",
      },
    });
    const { data } = response;
    totalReceiveInfoCompany.value = data.value;
  };

  const getConfigAppBannerInfoCompany = async () => {
    const { data: response } = await api.getConfigAppValues({
      params: {
        key: "banner_info_company",
      },
    });
    const { data } = response;
    isBannerInfoCompany.value = data.value;
  };

  onMounted(() => {
    /*const { welcome } = router.currentRoute.query;
    if (welcome === "info") isShowWelcomeVideo.value = true;*/
  });

  return {
    loadingValue,
    isShowWelcomeVideo,
    totalReceiveInfoCompany,
    isBannerInfoCompany,

    getConfigAppCreditCostReveiveInfoCompany,
    getConfigAppBannerInfoCompany,
  };
}
