<template>
  <v-dialog :value="isShowWelcomeVideo" width="700">
    <LazyVideo
      :src="'https://giphy.com/embed/1o1rax7qjgKOXpO6AA'"
      :poster="'https://cache.enlaceinmobiliario.cl/thumbs/FrontisOriginal2/DSC-6245b56fdc61b1.webp'"
      :attrs="{ controls: false, autoplay: true }"
    />
  </v-dialog>
</template>

<script>
export default {
  props: {
    isShowWelcomeVideo: {
      type: Boolean,
    },
  },
};
</script>

<style></style>
